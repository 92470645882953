import React, { lazy } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { AnimatePresence, m } from 'framer-motion';
import { Form, Formik } from 'formik';
import { Element } from 'react-scroll';
import CookieConsent from "react-cookie-consent"; // Import the library
import Header from '../../../Components/Header/Header';
import { supabase } from '../../../supabaseClient'; // Import Supabase client

// Functions
import { fadeIn, fadeInLeft } from '../../../Functions/GlobalAnimations';

// Components
import { resetForm } from "../../../Functions/Utilities";
import { Input } from '../../../Components/Form/Form';
import InViewPort from '../../../Components/InViewPort';

import './Startup.scss'; // Ensure this CSS file is created and linked

const ProcessStep = lazy(() => import('../../../Components/ProcessStep/ProcessStep'));
const MessageBox = lazy(() => import('../../../Components/MessageBox/MessageBox'));
const SideButtons = lazy(() => import("../../../Components/SideButtons"));
const StartupPageBannerSlider = lazy(() => import("./StartupBanner"));

const ProcessStepData = [
  {
    title: "Upload Commercial",
  },
  {
    title: "Bespoke Algorithm",
  },
  {
    title: "Vector Matching",
  },
  {
    title: "Segmental Results",
  },
  {
    title: "iab Catagories",
  },
];

const HomeStartupPage = (props) => {

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    const { email, firstname, lastname } = values;
    const { error } = await supabase
      .from('emails')
      .insert({ email, firstname, lastname });

    if (error) {
      console.error("Error inserting data: ", error);
    } else {
      console.log("Data successfully inserted!");
      resetForm(actions);
    }

    actions.setSubmitting(false);
  };

  return (
    <div style={props.style}>
      {/* Header Start */}
      <Header topSpace={{ md: true }} type="reverse-scroll" />

      <SideButtons />

      {/* TOP Section Start */}
      <Element name="top">
        <StartupPageBannerSlider />
      </Element>
      {/* TOP Section End */}

      {/* ABOUT Section Start */}
      <Element name="about">
        <section id="about" className="about-section">
          <div className="about-background">
            <div className="about-overlay" />
          </div>
          <Container className="about-container">
            <Row className="about-content align-items-center">
              <Col lg={6} className="about-text">
                <h2 className="about-title" style={{ color: 'black', fontSize: '4.5rem', fontWeight: 'bold' }}>Introducing ...</h2>
                <p className="about-description" style={{ color: 'black', fontSize: '2.5rem', marginTop: '10px', fontWeight: 'bold' }}>
                  BlimeyAI - Insights
                </p>
                <p className="about-sub-description" style={{ color: 'black', fontSize: '1.2rem', marginTop: '10px', fontWeight: 'bold' }}>
                  AI framework to help ad-fatigue on FAST & AVOD
                </p>
              </Col>
              <Col lg={6} className="about-video">
                <div className="video-wrapper" style={{ width: '700px', height: '400px', padding: '10px', marginLeft: 'auto', marginRight: '100px' }}>
                  <video width="100%" height="100%" controls>
                    <source src="https://blimey-upload.s3.eu-west-1.wasabisys.com/blimey_logo/Blimey_Demo_music_voice.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Element>
      {/* ABOUT Section End */}

      {/* Lazy Load HTML */}
      <InViewPort>
        {/* PRODUCT Section Start */}
        <Element name="services">
          <section id="services" className="py-[140px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden startup-processstep">
            <Container>
              <Row className="items-center justify-center">
                <Col xl={5} lg={6} md={10}>
                  <span className="font-serif text-xmd font-medium text-basecolor inline-block mb-[10px] sm:mb-[10px]">About the Framework</span>
                  <h2 className="heading-6 font-serif font-semibold text-darkgray block -tracking-[.5px] mb-20 w-[90%] lg:mb-[80px] md:mb-12 md:w-[80%] sm:w-full">Sentiment & Emotion Matching.</h2>
                  <ProcessStep grid="row-cols-1" theme='process-step-style-02' data={ProcessStepData} animation={fadeIn} />
                </Col>
                <m.div className="offset-xl-1 col-lg-6 md:mt-[50px]" {...fadeInLeft}>
                  <div className="-mr-[30vw] relative md:mr-0">
                    <img src="/assets/img/webp/home-startup-business-process.webp" width={1109} height={638} className="overflow-hidden" alt="" />
                  </div>
                </m.div>
              </Row>
            </Container>
          </section>
        </Element>
        {/* PRODUCT Section End */}

        {/* CONTACT Section Start */}
        <Element name="contact">
          <m.section id="contact" className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] cover-background overflow-visible" style={{ backgroundColor: "#FFFFFF" }} {...fadeIn}>
            <Container>
              <Row className="justify-center">
                <Col md={10} lg={7} className="text-center">
                  <span className="font-serif font-medium text-basecolor text-xmd mb-[20px] inline-block sm:mb-[10px]">We would love to hear from you.</span>
                  <h2 className="heading-4 font-serif font-semibold text-darkgray inline-block text-center mb-[65px] xs:mb-[30px]">Interested In Information on Merger & Acquisition.</h2>
                  <Formik
                    initialValues={{ email: '', firstname: '', lastname: '' }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email("Invalid email.").required("Field is required."),
                      firstname: Yup.string().required("Field is required."),
                      lastname: Yup.string().required("Field is required."),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, status }) => (
                      <div className="relative subscribe-style-04 w-[93%] mx-auto xs:w-full">
                        <Form className="relative">
                          <div className="form-row">
                            <div className="form-group half-width">
                              <Input showErrorMsg={false} type="text" name="firstname" className="border-[1px] border-solid border-transparent large-input" placeholder="Enter your first name" />
                            </div>
                            <div className="form-group half-width">
                              <Input showErrorMsg={false} type="text" name="lastname" className="border-[1px] border-solid border-transparent large-input" placeholder="Enter your last name" />
                            </div>
                          </div>
                          <div className="form-group full-width">
                            <Input showErrorMsg={false} type="email" name="email" className="border-[1px] border-solid border-transparent large-input" placeholder="Enter your email address" />
                          </div>
                          <div className="form-group">
                            <button aria-label="submit" type="submit" className={`text-xs tracking-[1px] py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`}>Subscribe Now</button>
                          </div>
                        </Form>
                        <AnimatePresence>
                          {status && (
                            <m.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              className="font-serif absolute top-[115%] left-0 w-full"
                            >
                              <MessageBox className="py-[5px] rounded-[4px] z-[2]" theme="message-box01" variant="success" message="Your message has been successfully sent. You are subscribed to our email list!" />
                            </m.div>
                          )}
                        </AnimatePresence>
                      </div>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Container>
          </m.section>
        </Element>
        {/* CONTACT Section End */}
      </InViewPort>

      {/* Cookie Consent */}
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="blimeyCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}>Learn More</span>
      </CookieConsent>
    </div>
  );
};

export default HomeStartupPage;
