const HeaderData = [
  {
    path: 'about',  // Section ID
    title: 'About'
  },
  {
    path: 'services',  // Section ID
    title: 'Services'
  },
  {
    path: 'contact',  // Section ID
    title: 'Contact'
  }
];

export default HeaderData;
