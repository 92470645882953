import React, { useEffect, useState, useContext, memo } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import { Container, Navbar } from "react-bootstrap";
import GlobalContext from "../../Context/Context";
import HeaderData from "./HeaderData";
import "../../Assets/scss/layouts/_header.scss";

/* Header Component Start */
export const Header = memo((props) => {
  const { setHeaderHeight } = useContext(GlobalContext);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let headerEl = document.querySelector("header");

    function setTopSpace() {
      let windowWidth = window.innerWidth,
        headerheight = (props.topSpace.desktop && props.topSpace.desktop === true) ? headerEl.offsetHeight : 0;

      if (windowWidth <= 1199 && props.topSpace.lg) {
        headerheight = props.topSpace.lg === true ? headerEl.offsetHeight : 0;
      }

      if (windowWidth <= 991 && props.topSpace.md) {
        headerheight = props.topSpace.md === true ? headerEl.offsetHeight : 0;
      }

      if (windowWidth <= 767 && props.topSpace.sm) {
        headerheight = props.topSpace.sm === true ? headerEl.offsetHeight : 0;
      }

      if (windowWidth <= 575 && props.topSpace.xs) {
        headerheight = props.topSpace.xs === true ? headerEl.offsetHeight : 0;
      }

      setHeaderHeight(headerheight);
    }

    setTopSpace();

    window.addEventListener('resize', setTopSpace);
    return () => {
      window.removeEventListener('resize', setTopSpace);
    }
  }, [location, props.topSpace, setHeaderHeight]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="sticky-header" style={{ background: 'linear-gradient(to right, rgb(255, 179, 179), rgb(107, 107, 255))', position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
      <Container fluid>
        <Navbar expand="lg" className="justify-content-between">
          <Navbar.Brand as={RouterLink} to="/" className="absolute-left">
            <img src="https://blimey-upload.s3.eu-west-1.wasabisys.com/blimey_logo/blimeyai_v1.png" alt="BlimeyAI" className="navbar-logo" />
          </Navbar.Brand>
          <button className="navbar-toggler" type="button" onClick={toggleMobileMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`navbar-center ${isMobileMenuOpen ? 'show' : ''}`}>
            <nav>
              <ul className="navbar-nav">
                {HeaderData.map((item, i) => (
                  <li key={i} className="nav-item">
                    <ScrollLink 
                      className="nav-link" 
                      to={item.path} 
                      smooth={true} 
                      duration={500}
                      offset={-70} // Adjust based on your header height
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.title}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="absolute-right">
            <RouterLink className="login-button" to="/login">Login</RouterLink>
          </div>
        </Navbar>
      </Container>
    </header>
  );
});

Header.propTypes = {
  topSpace: PropTypes.object
}

Header.defaultProps = {
  topSpace: {
    desktop: true,
    lg: true,
    md: true,
    sm: true,
    xs: true,
  }
}

export default Header;
